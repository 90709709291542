.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/* .trigger:hover {
  color: #1890ff;
} */

.layoutLogo {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1.2rem;
  height: 42px;
  margin: 10px;
}

.site-layout .site-layout-background {
  background: #ffffff;
}

.layout-top-bar{
  width: '100%';
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 25px;
}

.labelText {
  color: #555555;
  font-size: 1em;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

/* .content-background-img{
  background-image: url('/the7thday_content_background.png') !important;
} */

.global-font-color{
  color: '#737373' !important;
}

@font-face {
  font-family: 'HanyiSentyMarshmallow';
  src: url('/HanyiSentyMarshmallow.ttf') format('truetype'); /* Adjust the path as necessary */
  font-weight: normal;
  font-style: normal;
}

.font-type-daily-verse {
  font-family: 'HanyiSentyMarshmallow', sans-serif;
}